body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Noto Sans Simplified Chinese";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("https://res.cloudinary.com/dmatgvjjy/image/upload/v1678233148/Seasinglab/fondoBody_nhecjy.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* ----------------------Navbar---------------------------*/
a {
  text-decoration: none;
  color: black;
}

a:hover {
  transition: all 1s ease;
  color: grey;
}

.nav-styles {
  background-color: rgba(0, 0, 0, 0.4);
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.71);
}

.nav-links {
  font-size: 1.5rem;
  color: white;
  letter-spacing: 1.3px;
}

.nav-links :hover {
  color: lightgray;
  transition: all 1.5s ease;
}

#basic-nav-dropdown {
  color: white;
}
.seasing-logo {
  height: 85px;
}

/* ----------------------hero---------------------------*/
.hero {
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)),
    url("https://res.cloudinary.com/dmatgvjjy/image/upload/v1677523041/Seasinglab/lab_1_ykolrc.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
  position: relative;
  z-index: 1000;
  color: whitesmoke;
}

.seasing-title {
  font-family: "Noto Sans Simplified Chinese";
  font-size: 4rem;
  font-weight: 500;
}

h1 {
  
  font-family: "Noto Sans Simplified Chinese";
  font-size: 3rem;
}

.bannerParagraph {
  font-size: 1.8rem;
}

.btn-hero {
  padding: 1rem 1rem;
  background-color: rgb(0, 153, 185);
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.5s ease;
  font-size: 1.5rem;
}

.btn-hero:hover {
  background-color: rgb(3, 115, 144);
  color: lightgray;
}

/* ----------------------about us---------------------------*/
.us-border {
  border: 5px solid #0099b9;
  border-radius: 25px;
}

.paragraph-us {
  font-family: "Roboto";
  font-size: 1.5rem;
  line-height: 1.5em;
}

/* ----------------------clients carousel---------------------------*/
.clients-carousel {
  background-color: whitesmoke;
}

/* ----------------------form---------------------------*/
.contact {
  border: 5px solid #0099b9;
  border-radius: 25px;
}
.formulario {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

form {
  width: 50%;
}

label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 1.2rem;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 5px rgb(0, 153, 185, 0.2);
}

button[type="submit"] {
  background-color: rgb(3, 115, 144);
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  text-transform: uppercase;
  transition: all ease-in-out 3ms;
}
button[type="submit"]:hover {
  background-color: rgb(0, 153, 185);
}

/* ----------------------footer---------------------------*/

footer {
  background-color: rgb(0, 153, 185, 0.2);
}

.footer-styles {
  font-size: 1.5rem;
}

.footer-icons-styles {
  width: 30px;
  height: 30px;
  margin-right: 0.5rem;
}

.footer-general {
  display: flex;
}

.btn-wpp {
  border-radius: 50%;
  background-color: green;
  padding: 1rem;
  position: fixed;
  right: 1.2rem;
  bottom: 3rem;
  z-index: 2000;
  transition: all 1s ease-in-out;
}
.btn-wpp :hover {
  background-color: #0099b9 !important;
}

/* ----------------------btn back to top---------------------------*/
.back-to-top-btn {
  position: fixed;
  bottom: 10rem;
  right: 2.6rem;
  border: none;
  background-color: transparent;
  color: #010101;
  width: 40px;
  height: 40px;
}

/* ----------------------copyright---------------------------*/
.copy-container {
  background: rgb(10, 10, 10);
}
.copyright {
  color: white;
  font-size: 1rem;
}

/* ----------------------MQ---------------------------*/
@media (max-width: 600px) {
  #basic-navbar-nav {
    height: 15vh;
    overflow: hidden;
    transition: all 1s ease-in-out;
    background-color: rgba(0, 0, 0, 0.4);
    padding-top: 1rem;
  }
  .navbar-toggler {
    margin-right: 0.5rem;
    color: lightgray;
    border: lightgray 1.5px solid;
  }
}

@media only screen and (max-width: 768px) {
  form {
    width: 80%;
  }
}

@media (min-width: 1000px) {
  .footer-general {
    display: flex;
    justify-content: center;
  }
}
